<script setup lang="ts">
import { TitleRenderAsType, HtmlHeadings } from '~/lib/services/content/content.blocks.dtos'

const props = withDefaults(defineProps<{ text: string; renderAs?: TitleRenderAsType }>(), {
  renderAs: HtmlHeadings.H2,
})

const isH1 = computed(() => props.renderAs === HtmlHeadings.H1)
const isH2 = computed(() => props.renderAs === HtmlHeadings.H2)
const isH3 = computed(() => props.renderAs === HtmlHeadings.H3)
const isH4 = computed(() => props.renderAs === HtmlHeadings.H4)
const isH5 = computed(() => props.renderAs === HtmlHeadings.H5)
const isH6 = computed(() => props.renderAs === HtmlHeadings.H6)
</script>

<template>
  <h1 v-if="isH1">{{ text }}</h1>
  <h2 v-else-if="isH2">{{ text }}</h2>
  <h3 v-else-if="isH3">{{ text }}</h3>
  <h4 v-else-if="isH4">{{ text }}</h4>
  <h5 v-else-if="isH5">{{ text }}</h5>
  <h6 v-else-if="isH6">{{ text }}</h6>
</template>
